<template>
  <!-- Vista general de procesos -->
  <section fluid>
    <p class="text-h4 secondary--text mb-0">Procesos de compra</p>
    <p class="text-h6 secondary--text m-0">Ejercicio {{ anioFiscal }}</p>

    <v-row align="center">
      <v-col cols="12" sm="12" md="4">
        <v-text-field v-model="filters.nombre" label="Código / Nombre de proceso" outlined hide-details clearable/>
      </v-col>

      <v-col cols="12" sm="12" md="4">
        <v-autocomplete label="Modalidad de compra" :items="ctlModalidades" item-value="id" item-text="nombre" outlined
                        hide-details clearable v-model="filters.id_forma_contratacion"/>
      </v-col>
      <v-col v-if="haveRoles(['ROLE_PROCESOS_RESERVA'])" cols="12" sm="12" md="4">
        <v-autocomplete label="Interés del proceso" :items="ctlIntereses" item-value="id" item-text="nombre" outlined
                        hide-details clearable v-model="filters.id_interes_presidente"/>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-autocomplete label="Estado" :items="ctlEstados" item-value="id" item-text="nombre" outlined hide-details
                        clearable v-model="filters.id_seguimiento_proceso"/>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-menu
            ref="menuDataRange"
            v-model="menuDataRange"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field outlined v-model="dateRangeText" label="Desde - Hasta" prepend-inner-icon="mdi-calendar"
                          readonly hide-details v-bind="attrs" v-on="on" @click:clear=""/>
          </template>
          <v-date-picker title="es" :max="fechaMaximaFiltro" v-model="fechas" range @change="setFiltrosFechas"
                         locale="es-Es">
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-autocomplete label="Institución" :items="ctlInstituciones" item-value="id" item-text="nombre" outlined
                        hide-details clearable v-model="filters.id_institucion"/>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <div class="d-flex align-center justify-space-around">
          <v-col>
            <v-btn dark block color="secondary" @click="obtainListProcesos()">Buscar</v-btn>
          </v-col>
          <v-col>
            <v-btn outlined block color="secondary" @click="clearFiltros()">Limpiar</v-btn>
          </v-col>
        </div>
      </v-col>
    </v-row>
    <p class="font-weight-bold secondary--text mt-8">Listado de procesos</p>
    <div v-if="haveRoles(['ROLE_PROCESOS_RESERVA'])" class="d-flex align-center mb-2">
      <v-icon class="customProcessColor">mdi-circle</v-icon>
      <p class="mb-0 ml-2">Procesos reservados</p>
    </div>
    <data-table-component v-models:select="perPage" v-models:pagina="page" :headers="headers" :items="items"
                          @paginar="paginate" :total_registros="totalRows">
      <template #[`item.id_tipo_proceso`]="{ item }">
        <v-icon
            v-if="item.id_tipo_proceso == 2 && haveRoles(['ROLE_PROCESOS_RESERVA'])"
            class="mr-2 customProcessColor font-25">mdi-circle
        </v-icon>
      </template>
      <template #[`item.institucion`]="{ item }">
        <span>
          {{ item.EmpleadoUnidad?.Unidad?.Institucion?.nombre }}
        </span>
      </template>
      <template #[`item.codigo_proceso`]="{ item }">
        <span>
          {{ item.codigo_proceso }}
        </span>
      </template>
      <template #[`item.fecha_inicio_proceso`]="{ item }">
        <span>
          {{ moment(item.fecha_inicio_proceso).format("MMMM / YYYY") }}
        </span>
      </template>
      <template #[`item.fecha_contratacion`]="{ item }">
        <span>
          {{ moment(item.fecha_contratacion).format("DD/MM/YYYY") }}
        </span>
      </template>
      <template v-slot:[`item.suma_mercancia`]="{ item }">
        {{
          Intl.NumberFormat("en-US", {
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(item.suma_mercancia)
        }}
      </template>
      <template v-slot:[`item.estado`]="{ item }">
        <v-chip label dark :color="item.seguimiento_proceso?.color" class="mt-1" v-if="item.EstadoProceso.id == 1">
          {{ item.seguimiento_proceso?.nombre || "" }}
        </v-chip>
        <v-chip v-if="item.EstadoProceso.id !== 1" :color="procesoEstado(item.EstadoProceso.id)" label
                class="mt-1 white--text">
          {{ item.EstadoProceso.nombre }}
        </v-chip>
        <p v-if="
        item.EstadoProceso.id === 4 &&
        item.id_tipo_proceso === 2"
        >Este proceso está en construcción, en procesos con reserva</p>
      </template>
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip top v-if="item.EstadoProceso.id !== 2">
          <template v-slot:activator="{ on, attrs }">
            <v-icon @click="
              $router.push({
                name: 'pac-procesos-etapas',
                params: { idProceso: item.id, supervisor: 'supervisor' },
              })
              " v-bind="attrs" v-on="on" color="secondary">
              mdi-eye
            </v-icon>
          </template>
          <span> Ver seguimiento</span>
        </v-tooltip>
      </template>
    </data-table-component>
  </section>
</template>

<script>
import {mapState} from 'vuex';
import DataTableComponent from '@/components/DataTableComponent.vue';
import moment from 'moment';

export default {
  name: 'ProcesosCompraView',
  components: {
    DataTableComponent,
  },
  data: () => ({
    headers: [
      {
        text: '',
        value: 'id_tipo_proceso',
        sortable: false,
        align: 'center',
      },
      {
        text: 'Código',
        value: 'codigo_proceso',
      },
      {
        text: 'Proceso',
        value: 'nombre_proceso',
      },
      {
        text: 'Modalidad de compra',
        value: 'forma_contratacion.nombre',
        align: 'right',
      },
      {
        text: 'Institución',
        value: 'institucion',
        align: 'right',
      },
      {
        text: 'Mes programado',
        value: 'fecha_inicio_proceso',
        align: 'right',
      },
      {
        text: 'Fecha solicitado',
        value: 'fecha_contratacion',
        align: 'right',
      },
      {
        text: 'Monto programado($)',
        value: 'suma_mercancia',
        align: 'center',
      },
      {
        text: 'Estado',
        value: 'estado',
        align: 'center',
      },
      {
        text: 'Acciones',
        value: 'acciones',
        align: 'center',
        sortable: false,
      },
    ],
    items: [],
    filters: {
      id_seguimiento_proceso: null,
      id_forma_contratacion: null,
      nombre: null,
      fecha_desde: null,
      fecha_hasta: null,
      id_interes_presidente: null,
      id_institucion: null,
    },
    totalRows: 0,
    page: 1,
    perPage: 10,
    ctlModalidades: [],
    ctlEstados: [
      {
        id: 1,
        nombre: 'Configuración proceso',
      },
      {
        id: 11,
        nombre: 'Recepción de solicitudes',
      },
      {
        id: 3,
        nombre: 'En ejecución',
      },
      {
        id: 5,
        nombre: 'Contratación',
      },
    ],
    ctlIntereses: [
      {id: 3, nombre: 'Todos'},
      {id: 1, nombre: 'Procesos de indole institucional'},
      {id: 2, nombre: 'Procesos reservados'},
    ],
    timer: null,
    modalAddProceso: false,
    deleteDialog: false,
    proceso_seleccionado: null,
    menuDataRange: false,
    fechas: ['', ''],
    ctlInstituciones: [],
  }),

  computed: {
    ...mapState(['anioFiscal', 'userInfo']),
    fechaMaximaFiltro() {
      // Fecha actual en formato ISO 8601 format
      return moment().format('YYYY-MM-DD');
    },
    dateRangeText() {
      let value = '';
      if (this.fechas[0] === '' && this.fechas[1] === '') {
        value = ' ';
      } else {
        value = `${moment(this.fechas[0]).format('D/M/Y')} - ${moment(this.fechas[1]).format('D/M/Y')}`;
      }
      return value;
    },
  },

  methods: {
    paginate(params) {
      const {cantidad_por_pagina, pagina} = params;
      this.perPage = cantidad_por_pagina;
      this.page = pagina;
      this.obtainListProcesos();
    },
    filterName() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(async () => {
        await this.obtainListProcesos();
      }, 500);
    },
    async fetchModalidades() {
      const response = await this.services.Paac.getModalidades({
        compra_tradicional: true,
      });
      if (response) {
        this.ctlModalidades = response.data.forma_contratacion;
      }
    },
    async fetchInstituciones() {
      const response =
          await this.services.Instituciones.getCatalogoInstituciones({
            pagination: false,
          });
      if (response) {
        this.ctlInstituciones = response.data;
      }
    },
    setFiltrosFechas() {
      this.filters.fecha_desde = this.fechas[0];
      this.filters.fecha_hasta = this.fechas[1];
    },
    clearFiltros() {
      this.filters.nombre = null;
      this.filters.id_forma_contratacion = null;
      this.filters.id_interes_presidente = null;
      this.filters.id_seguimiento_proceso = null;
      this.fechas = ['', ''];
      this.filters.fecha_desde = null;
      this.filters.fecha_hasta = null;
      this.filters.id_institucion = null;
      this.obtainListProcesos();
    },
    async obtainListProcesos() {

      const {status, data, headers} =
          await this.services.Paac.getListProcesos({
            ...this.filters,
            page: this.page,
            per_page: this.perPage,
          });
      if (status === 200) {
        this.items = data.procesos;
        this.totalRows = Number(headers.total_rows);
      }

    },
    closeModalAddProceso() {
      this.modalAddProceso = false;
    },
    async deleteProceso() {

      const {status} = await this.services.PacProcesos.deleteProcesoCompra(
          this.proceso_seleccionado,
      ).catch(() => {

      });
      if (status === 200) {
        this.temporalAlert({
          show: true,
          message: 'Proceso eliminado con éxito',
          type: 'success',
        });
        setTimeout(() => {

        }, 1500);
        this.obtainListProcesos();
      }
      this.deleteDialog = false;
    },
    asignarCdp(idProceso) {
      this.$router.push({name: 'proceso-asignar-cdp', params: {idProceso}});
    },
    procesoEstado(id) {
      let clase = '';
      switch (id) {
        case 5:
          clase = 'error';
          break;
        case 6:
          clase = 'amber lighten-3';
          break;
        case 7:
          clase = 'purple lighten-2';
          break;
        case 8:
          clase = 'info';
          break;
        default:
          clase = 'default';
          break;
      }
      return clase;
    },
  },
  created() {
    this.obtainListProcesos();
    this.fetchModalidades();
    this.fetchInstituciones();
  },
};
</script>
<style>
.customProcessColor {
  color: #73d2de !important;
}

.font-25 {
  font-size: 25px !important;
}
</style>
